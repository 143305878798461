import React from 'react'
import * as Chakra from '@chakra-ui/react'
import DashIcon from '../icons/dash-icon'

const PageInfo = ({
  numCurrent,
  numTotal,
  color,
  title,
  noHeader,
  props,
}) => {
  return (
    <Chakra.Flex
      w="100%"
      flexDirection="column"
      {...props}
      textStyle="basisGrotesque"
    >
      {!noHeader && (
        <Chakra.Text
          fontSize={{
            base: '29px',
            md: '38px',
            lg: '46px',
            xl: '72px',
            '2xl': '96px',
          }}
          mb="10px"
        >
          {numCurrent}
        </Chakra.Text>
      )}
      <Chakra.Divider
        borderColor={color}
        opacity="1"
        noshade="noshade"
        w="100%"
        position="relative"
      />
      <Chakra.Flex
        mt="20px"
        alignItems="center"
        textTransform="uppercase"
        fontSize={{
          base: '8px',
          md: '8px',
          lg: '8px',
          xl: '14px',
          '2xl': '18px',
        }}
      >
        <Chakra.Text>
          {numCurrent} / {numTotal}
        </Chakra.Text>
        <DashIcon
          fill={color}
          style={{ margin: '0 32px' }}
        />
        <Chakra.Text>{title}</Chakra.Text>
      </Chakra.Flex>
    </Chakra.Flex>
  )
}

export default PageInfo
